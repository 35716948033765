import React, { Component, lazy, Suspense } from "react";
import Loading from "./components/Loading";

const promise = new Promise(resolve => setTimeout(resolve, 3000));


const Navbar = lazy(() => {
  return promise.then(() => import(`./components/Navbar`));
});
const Home = lazy(() => {
  return promise.then(() => import(`./components/Home`));
});
const About = lazy(() => {
  return promise.then(() => import(`./components/About`));
});
const Work = lazy(() => {
  return promise.then(() => import(`./components/Work`));
});
const Skill = lazy(() => {
  return promise.then(() => import(`./components/Skill`));
});
const Contact = lazy(() => {
  return promise.then(() => import(`./components/Contact`));
});
const Footer = lazy(() => {
  return promise.then(() => import(`./components/Footer`));
});

export default class App extends Component {

  render() {
    return (
      <div className="App">
          <Suspense fallback={<Loading />}>
          <section className="main">
            <Navbar />
            <Home />
            <About />
            <Work />
            <Skill />
            <Contact />
          </section>
            <Footer />
          </Suspense>
      </div>
    );
  }
}
