import React from "react";

export default () => {
  return (
    <div className="loading">
      <div className="svg loading-box">
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 500 500">
          <circle cx="297" cy="238" r="2.5" />
          <path
            className="st3 path path2"
            d="M297,241c-1.65,0-3-1.35-3-3s1.35-3,3-3s3,1.35,3,3S298.65,241,297,241z M297,236c-1.1,0-2,0.9-2,2s0.9,2,2,2
            s2-0.9,2-2S298.1,236,297,236z"
          />

          <path
            className="path path3"
            d="M299,239.49c-1.67,1.49-5-0.01-4-2.98C295.95,234.32,300.93,236,299,239.49z"
          />
          <path
            className="st0 path"
            d="M280.53,186.32c9.09-8.62,8.97-13.91-5.03-14.15c-37.34,10.84-49.63,13.91-57.49,8.49
            c5.73-4.22,13.34-3.19,22.58,2.34c9.42,8.02,7.2,31.41,2.52,48.3"
          />
          <path
            className="st3 path path4"
            d="M260.42,259.17c-35.94,5.75-69.85-18.81-75.59-54.75s18.81-69.85,54.75-75.59s69.85,18.81,75.59,54.75
            c1.95,12.2,0.46,24.63-4.3,35.96l-0.92-0.39c4.69-11.16,6.16-23.4"
          />
        </svg>
      </div>
    </div>
  );
};
